import './App.css';
import { Box, Link, Stack, Typography } from '@mui/material';

import logo from './orange-dog.jpeg';

function App() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Link href="https://darnbots.com" target="_blank">
          <Box
            component="img"
            sx={{
              height: 250,
              width: '100%',
              maxWidth: '90vw',
            }}
            alt={'Wave Bot'}
            src={'https://static.darnbots.com/icons/waveBot.svg'}
          />
        </Link>
        <Stack spacing={2}>
          <Box>
            <Typography
              sx={{
                p: 1,
                fontFamily: 'Orbitron',
                fontWeight: 600,
              }}
              variant="h4"
            >
              Darn Bots
            </Typography>
            <Typography fontWeight={600} variant="h6">
              Build your own darn bot
            </Typography>
          </Box>
          <Box
            component="img"
            sx={{
              height: 50,
            }}
            onClick={() => {
              window.open(
                'https://apps.apple.com/app/id6451425567?platform=iphone',
                '_blank'
              );
            }}
            alt={'Get Darn Bots on the App Store'}
            src={'/app_store_download.svg'}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          mt: 10,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <img
          alt="Dog Day Apps, LLC logo"
          src={logo}
          style={{ height: 150, width: 150 }}
        />
        <Typography fontWeight={600} sx={{ textAlign: 'center' }}>
          © 2024 Dog Day Apps, LLC
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
